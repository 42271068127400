import "phoenix_html"
import { Socket } from "phoenix"
import { LiveSocket } from "phoenix_live_view"
import topbar from "../vendor/topbar"

let Hooks = {}

Hooks.ReloadJS = {
  mounted() {
    console.log("LiveView Mounted: Reapplying JS");
    setTimeout(reinitializeJavaScript, 500);
  },
  updated() {
    console.log("LiveView Updated: Reapplying JS");
    setTimeout(reinitializeJavaScript, 500);
  }
}

// ✅ Function to Reinitialize All JavaScript Components
function reinitializeJavaScript() {
    console.log("Reinitializing JavaScript...");
  
    // ✅ Swiper Initialization with Correct Speed
    if (typeof Swiper !== "undefined") {
      document.querySelectorAll(".swiper-container").forEach((el) => {
        new Swiper(el, {
          loop: true,
          speed: 1000,
          navigation: {
            nextEl: el.nextElementSibling,
            prevEl: el.nextElementSibling.nextElementSibling,
          },
        });
      });
      console.log("✅ Swiper initialized with correct speed!");
    } else {
      console.warn("⚠️ Swiper.js is missing!");
    }
  }
  
// Ensure CSRF token is set correctly
let csrfMetaTag = document.querySelector("meta[name='csrf-token']");
let csrfToken = csrfMetaTag ? csrfMetaTag.getAttribute("content") : "";

let liveSocket = new LiveSocket("/live", Socket, {
  params: { _csrf_token: csrfToken },
  hooks: Hooks
})

topbar.config({ barColors: { 0: "#29d" }, shadowColor: "rgba(0, 0, 0, .3)" })
window.addEventListener("phx:page-loading-start", _info => topbar.show(300))
window.addEventListener("phx:page-loading-stop", _info => topbar.hide())

liveSocket.connect()
window.liveSocket = liveSocket
